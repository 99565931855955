import React from "react"
import withLayout from "../../layout"
import SEO from "../../components/SEO";
import {injectIntl} from "react-intl";
import EventDetailTemplate from "../../components/EventDetailTemplate"
import eventsList from "../../utilities/webinars"
import LottieController from "../../components/UI/LottieController"
import MembersLottieIcon from "../../components/UI/Lottie/Colleghi.json"
import HandLottieIcon from "../../components/UI/Lottie/multifunzionali.json"
import EyeLottieIcon from "../../components/UI/Lottie/Occhio.json"
import SimpleStats from "../../components/UI/SimpleStats"
import {useInView} from "react-intersection-observer"
import TestimonialImage from "../../images/agency/pasquale_gallo.jpg"

const SingleEventPageWrapper = ({intl}) => {
    const {
        name,
        link,
        title,
        date,
        times,
        price,
        image,
        imagesrc,
        id,
        canSubscribe
    } = eventsList.find(event => event.link === "l-inbound-marketing-nel-settore-sanitario")

    const templateData = {
        canSubscribe,
        name,
        link,
        title,
        testimonial: {
            image: TestimonialImage,
            name: "Pasquale Gallo",
            role: "Project Manager | Kromin"
        },
        date,
        times,
        location: "events.online",
        price,
        image,
        imagesrc,
        id,
        description: "l_inbound_marketing_nel_settore_sanitario.description",
        list: [
            "l_inbound_marketing_nel_settore_sanitario.list_1",
            "l_inbound_marketing_nel_settore_sanitario.list_2",
            "l_inbound_marketing_nel_settore_sanitario.list_3",
            "l_inbound_marketing_nel_settore_sanitario.list_4"
        ],
        categories: [
            "events.marketing",
            "events.data"
        ],
        results: <Results/>,
        intl
    }
    const keywords = intl.formatMessage({id:"events.meta_keywords"}).split(",") || []

    return (
        <>
            <SEO
                title={intl.formatMessage({id: "events.meta_title"})}
                description={intl.formatMessage({id: "events.meta_description"})}
                keywords={keywords}
                lang={intl.locale}
            />

            <EventDetailTemplate
                {...templateData}
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "events", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(SingleEventPageWrapper))

const Results = () => {
    const [resultIcon1Ref, resultIcon1View] = useInView()
    const [resultIcon2Ref, resultIcon2View] = useInView()
    const [resultIcon3Ref, resultIcon3View] = useInView()

    return (
        <div className="row">
            <div className="col-12 col-lg-6 col-xl-4 mb-4 mb-xl-0">
                <div ref={resultIcon1Ref}>
                    <LottieController
                        lottieJSON={MembersLottieIcon}
                        inView={resultIcon1View}
                        width="120px"
                        height="120px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px!important"
                        prefix="+"
                        measureUnit="K%"
                        inView={resultIcon1View}
                        valueFrom={0}
                        valueTo={30}
                        label="l_inbound_marketing_nel_settore_sanitario.results_1"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{
                            color: "#FFF",
                            margin: 0
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4 mb-4 mb-xl-0">
                <div ref={resultIcon2Ref}>
                    <LottieController
                        lottieJSON={HandLottieIcon}
                        inView={resultIcon2View}
                        width="120px"
                        height="120px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px!important"
                        prefix="+"
                        measureUnit="K%"
                        inView={resultIcon2View}
                        valueFrom={0}
                        valueTo={12}
                        label="l_inbound_marketing_nel_settore_sanitario.results_2"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{
                            color: "#FFF",
                            margin: 0
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4 mb-4 mb-xl-0">
                <div ref={resultIcon3Ref}>
                    <LottieController
                        lottieJSON={EyeLottieIcon}
                        inView={resultIcon3View}
                        width="120px"
                        height="120px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px!important"
                        prefix=""
                        measureUnit=""
                        inView={resultIcon3View}
                        valueFrom={0}
                        valueTo={28555}
                        separator="."
                        label="l_inbound_marketing_nel_settore_sanitario.results_3"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{
                            color: "#FFF",
                            margin: 0
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
